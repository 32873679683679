<script setup lang="ts">
import { useEditablePage } from '/~/composables/cms/use-editable-page'

const { page } = useEditablePage()
</script>

<template>
  <div class="mx-auto h-full w-full px-5 md:max-w-7xl">
    <widgets-builder
      v-if="page && page.widgets"
      :widgets="page.widgets"
      class="pb-10 pt-5 sm:pt-[30px]"
    />
  </div>
</template>
